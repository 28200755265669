class WhooDynamicFAQs {
    constructor() {
        const whooDynamicFaqsElement = document.querySelector(".whoo-dynamic-faqs");
        if (!whooDynamicFaqsElement) {
            return;
        }

        const whooDynamicFaqItems = whooDynamicFaqsElement.querySelectorAll(".whoo-dynamic-faqs-item");
        this.addEventListeners(whooDynamicFaqItems);
    }

    addEventListeners(faqItems = []) {
        if (!faqItems || faqItems.length === 0) {
            return;
        }

        faqItems.forEach(faqItem => {

            const faqItemTitle = faqItem.querySelector(".whoo-dynamic-faqs-item__title");
            const faqItemContent = faqItem.querySelector(".whoo-dynamic-faqs-item__content");

            faqItemTitle.addEventListener("click", event => {
                faqItem.classList.toggle("whoo-dynamic-faqs-item_opened");

                if (faqItemContent.style.maxHeight){
                    faqItemContent.style.maxHeight = null;
                } else {
                    faqItemContent.style.maxHeight = faqItemContent.scrollHeight + "px";
                }
            })

        });
    }
}

export default WhooDynamicFAQs;